import Vue from "vue";
import VueRouter from "vue-router";
import Wartungsseite from "../views/Wartungsseite.vue";
import Impressum from "@/views/Impressum";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home - proCent",
    component: Wartungsseite,
  },
  {
    path: "/impressum",
    name: "impressum",
    component: Impressum,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

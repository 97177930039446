<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col align="center">
        <v-card width="600" class="pa-4" elevation="4">
          <v-card elevation="10">
            <v-img src="../assets/hans-werner_auto.jpg"></v-img>
          </v-card>
          <div class="ma-4">
            <h1>Die Website befindet sich im Umbau</h1>
            <h2>Sie haben Fragen?</h2>
            <h2>Melden Sie sich direkt bei uns</h2>
            <h2>
              <a href="mailto:info@procent-mohn.de"> info@procent-mohn.de </a>
            </h2>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

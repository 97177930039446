<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col align="center">
        <v-card width="700" align="left">
          <v-card-text>
            <article class="uk-article">
              <h1 class="my-4">Impressum</h1>

              <div>
                <strong
                  ><span><span>Hans Werner Mohn</span></span></strong
                >
              </div>
              <div>
                <span><span>Fliesenleger</span></span>
              </div>
              <div>
                <span><span>Konradin-Kreutzer-Str. 12</span></span>
              </div>
              <div>
                <span><span>79106 Freiburg</span></span>
              </div>
              <div>
                <span><span>Telefon: 0761 289603</span></span>
              </div>
              <div>
                <span><span>Telefax: 0761 38947972</span></span>
              </div>
              <div>
                <span>
                  <span>E-Mail: </span>
                  <span>
                    <a href="mailto:info@procent-mohn.de">
                      info@procent-mohn.de
                    </a>
                  </span>
                </span>
              </div>
              <div>&nbsp;</div>
              <div>
                <span
                  ><b><span>Umsatzsteuer-ID: </span></b></span
                >
              </div>
              <div>
                <span
                  ><span
                    >Umsatzsteuer-Identifikationsnummer nach §27a
                  </span></span
                >
              </div>
              <div>
                <span><span>Umsatzsteuergesetz:228970641</span></span>
              </div>
              <div>&nbsp;</div>
              <div>
                <span
                  ><span><b>Aufsichtsbehörde:</b></span
                  ><span> BG Bau Bezirksverwaltung Karlsruhe </span></span
                >
              </div>
              <div>
                <span
                  ><span
                    >Berufsbezeichnung: Fliesen, Platten und Mosaikleger</span
                  ></span
                >
              </div>
              <div>
                <span><span>Kammer: Freiburg</span></span>
              </div>
              <div>
                <span
                  ><span
                    >Berufsbezeichnung verliehen im Land: Deutschland</span
                  ></span
                >
              </div>
              <div>&nbsp;</div>
              <div>
                &nbsp;
                <p class="bodytext">
                  Hans-Werner Mohn übernimmt keine Gewährleistung und keine
                  Haftung im Zusammenhang mit jeglicher Nutzung dieser
                  Publikation oder für indirekte, zufällige oder Folgeschäden,
                  die durch oder in Folge der Benutzung dieser Website
                  aufgetreten sind. Alle auf dieser Website veröffentlichten
                  Informationen werden von&nbsp;Hans-Werner Mohn&nbsp;nach
                  bestem Wissen zur Verfügung gestellt, jedoch kann eine
                  Garantie für die Vollständigkeit, Richtigkeit und letzte
                  Aktualität der Informationen nicht übernommen werden. Alle
                  diese Informationen begründen, soweit gesetzlich zulässig,
                  weder eine Garantie, Zusage oder Haftung seitens Hans-Werner
                  Mohn.&nbsp;Hans-Werner Mohn behält sich das Recht vor, diese
                  rechtlichen Hinweise jederzeit zu ändern. Diese Änderungen
                  gelten für den jeweiligen Zeitpunkt Ihres Besuchs, und Sie
                  sollten deshalb bei jedem Besuch auch diese rechtlichen
                  Hinweise erneut nachlesen.
                </p>
                <p class="bodytext">
                  Der Inhalt dieser Webseite ist urheberrechtlich geschützt.
                  Vervielfältigung, Speicherung und Nachdruck nur mit
                  ausdrücklicher, schriftlicher Genehmigung von Hans-Werner
                  Mohn.
                </p>
              </div>
            </article>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Impressum",
};
</script>

<style scoped></style>

<template>
  <v-app id="app" class="full-wh overflow-hidden">
    <link rel="icon" href="./assets/favicon.ico" />
    <v-card>
      <v-app-bar
        dense
        outlined
        height="50"
        elevate-on-scroll
        elevation="4"
        scroll-target="#scrolling-techniques-7"
      >
        <router-link to="/" class="mx-4">
          <v-img src="@/assets/logo.png"></v-img>
        </router-link>
        <v-toolbar-title class="font-weight-bold">proCent</v-toolbar-title>
      </v-app-bar>
    </v-card>
    <v-main>
      <router-view />
    </v-main>
    <v-footer class="flex justify-center">
      <router-link to="/impressum" class="ml-2">Impressum</router-link>
      <div class="mx-2">|</div>
      <div>Copyright © 2022 by proCent</div>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style>
.full-wh {
  height: 100hv;
  width: 100hw;
}
</style>
